<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="box box-default box-solid box-component roles-box row">
          <div class="box-body box-details-header">
            <div class="col-xs-4">
              <div class="details-header">
                <div class="details-header--details">
                  <h2>Dane podstawowe</h2>
                  <ul class="list-unstyled">
                    <li><label>Nazwa:</label> {{ role.label }} <strong>{{ role.businessRole ? ' (rola biznesowa)' : '' }}</strong></li>
                    <li><label>Opis:</label> {{ role.description }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="box-header">
                <h3 class="box-title">
                  Edycja uprawnień
                </h3>
              </div>
              <form>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="role-tree">
                      <checkbox-tree
                        :groups="groups"
                        :disabled="false"
                        :action-accesses-tree="actionAccessesTree"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from '../../../../../../api'
import DynamicBreadcrumb from '../../../../share/mixins/DynamicBreadcrumb'
import ErrorNotify from '../../../../../share/mixins/ErrorNotify'
import Page from '../../../../../share/content/Page'
import CheckboxTree from '../tree/CheckboxTree'

export default {
  components: {
    CheckboxTree
  },
  mixins: [
    DynamicBreadcrumb,
    Page,
    ErrorNotify
  ],
  props: {
    service: { type: String, required: true }
  },
  data () {
    return {
      events: {
        submitData: `${this.service}:acl:submitData`
      },
      headerTitle: { title: 'Role', description: 'Edycja poziomów dostępu' },
      actionAccessesTree: [],
      role: {},
      groups: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      api.request(this.service, 'get', `/roles/${this.$route.params.id}`)
        .then((response) => {
          this.role = response.data

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(this.$route.name.split('_role_details')[0] + '_role_details', this.role.label)
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Błąd',
            text: error.errorMessage
          })
        })
      api.request(this.service, 'get', `/roles/${this.$route.params.id}/access-tree`)
        .then((response) => {
          this.actionAccessesTree = response.data
          this.groups = this.actionAccessesTree.map(accessNode => { return { 'group': accessNode.label, 'value': 0 } })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    })
    this.$events.on(this.events.submitData, this.submitData)
  },
  methods: {
    submitData () {
      let url = '/security/actions'
      api.request(this.service, 'put', url, this.createDataToSend(this.service))
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    // TODO: change for future services
    createDataToSend (service) {
        let arr = []
        this.actionAccessesTree.forEach((accessNode, index) => accessNode.actions && accessNode.actions
          .forEach(action => { if (action.value === 1 || action.value === true) { arr.push(action.id) } }))
        return {
          'roleId': this.$route.params.id,
          'actionsIds': arr
        }
    }
  }
}
</script>
